require('scss/modules/security.sass');

(() => {
  const passwordVisibleRows = document.getElementsByClassName('password-visible-row')
  for (let i = 0; i < passwordVisibleRows.length; i++) {
    const thisRow = passwordVisibleRows[i]
    const passTrigger = thisRow.getElementsByClassName('password-visible')[0]
    passTrigger.addEventListener('click', onIconClickCallback)
  }

  function onIconClickCallback (e) {
    const target = e.target
    const row = target.closest('.form__group')
    const input = row.getElementsByTagName('input')[0]
    const inputType = input.getAttribute('type')
    row.classList.toggle('pass-show')
    if (inputType === 'password') {
      input.setAttribute('type', 'text')
    }
    if (inputType === 'text') {
      input.setAttribute('type', 'password')
    }
  }
})()
